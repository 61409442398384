<template>
  <!-- Coming soon page-->
  <div class="register-success on-boarding">
    <b-row class="main-row">
      <b-col sm="12" lg="7" class="bg-white position-relative">
        <div class="main-content h-100">
          <div class="left-content text-center free-acc">
            <!-- Brand logo-->
            <b-link class="brand-logo pb-2 d-block pt-1" :to="{ name: 'home' }">
              <b-img
                style="width: 157px"
                :src="appDarkLogo"
                alt="logo"
              />
            </b-link>
            <!-- /Brand logo-->
            <div class="title-section pb-4 pt-3">
              <b-card-title title-tag="h1" class="font-weight-bolder text-align-center font-weight-bolder my-1">Congratulations</b-card-title>
              <b-card-text class="text-align-center">Your FREE Account Is All Set Up</b-card-text>
            </div>
            <div class="verfied-mail py-5">
              <h5>Verify your email, to get started!</h5>
              <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="inbox-btn mt-1">
                  <span class="align-middle">Check Your Inbox</span>
                </b-button>
                <!-- <p class="skip-text mt-1 cursor-pointer"><a href="">Skip Verfication</a></p> -->
            </div>
          </div>
          <div class="bottom-support pt-1">
            <p>Need more help? Write to us at <a class="text-primary" href="mailto: asupport@zircly.com">support@zircly.com</a></p>
          </div>
        </div>
      </b-col>
      <b-col sm="12" lg="5" class="board-rightimg d-none d-lg-flex align-items-center justify-content-center">
        <b-img
          fluid
          :src="imgUrl"
          alt="Coming soon page"
          class="pricing-trial-img"
        />
      </b-col>
    </b-row>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BForm, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/verify-email.svg'),
      support : process.env.VUE_APP_SUPPORT_EMAIL
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/verify-email.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app
    return {
      appName,
      appDarkLogo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>